export const decodeToken = (token) => {
    if (!token) return null;
    
    try {
      // JWT tokens are base64 encoded with 3 parts: header.payload.signature
      const base64Url = token.split('.')[1]; // Get the payload part
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      
      return payload;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  
  export const getUserIdFromToken = (token) => {
    const decodedToken = decodeToken(token);
    return decodedToken?.user_id;
  };
  
  export const getTokenExpirationTime = (token) => {
    const decodedToken = decodeToken(token);
    return decodedToken?.exp ? new Date(decodedToken.exp * 1000) : null;
  };
  
  export const isTokenExpired = (token) => {
    const expirationTime = getTokenExpirationTime(token);
    return expirationTime ? expirationTime < new Date() : true;
  };