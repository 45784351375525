import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CompleteTableDetails from "./CompleteTableDetails";
import TableComponent from "./TableComponent";
import NavigationBar from "./NavigationBar";
import { useAuth } from "../hooks/useAuth";

const Dashboard = () => {
  const [showTable, setShowTable] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const showTableView = () => {
    setShowTable(true);
  };

  const showCompleteDetailsView = () => {
    setShowTable(false);
  };

  const onAdminClick = () => {
    navigate("/admin"); // Navigate to the admin panel
  };

  const onCreateClick = () => {
    navigate("/create"); // Navigate to the create panel
  };
  const onUpdateClick = () => {
    navigate("/update"); // Navigate to the update panel
  };
  const onDeleteClick = () => {
    navigate("/delete"); // Navigate to the delete panel
  };

  return (
    <div className="Appx">
      <NavigationBar
        showTable={showTable}
        showTableView={showTableView}
        showCompleteDetailsView={showCompleteDetailsView}
        onAdminClick={onAdminClick}
        onCreateClick={onCreateClick}
        onUpdateClick={onUpdateClick}
        onDeleteClick={onDeleteClick}
        logout={logout}
      />
      <div className="containerx">
        <br />
        <h2 style={{ color: "maroon", textAlign: "center" }}>
          KI-Kompass Inklusiv: Datenbank für KI-gestützte Assistenztechnologien
          für Menschen mit Behinderungen
        </h2>
        <br />
      </div>
        {showTable ? <TableComponent /> : <CompleteTableDetails />}
    </div>
  );
};

export default Dashboard;
