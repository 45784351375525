export default class ApiService {
    constructor(baseURL) {
      this.baseURL = baseURL;
    }
  
    getAuthToken() {
      const token = localStorage.getItem('authToken');
      return token ? token.replace(/^["'](.+)["']$/, '$1') : null;
    }
  
    async apiRequest(endpoint, method = "GET", body = null) {
      const token = this.getAuthToken();
      const headers = { "Content-Type": "application/json" };
  
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }
  
      const api_url = `${this.baseURL}${endpoint}`;
      const options = {
        method,
        headers
      };
  
      if (body) {
        options.body = JSON.stringify(body);
      }
  
      try {
        const response = await fetch(api_url, options);
        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message);
        }
        return await response.json();
      } catch (error) {
        console.error(`Error during ${method} ${endpoint}:`, error);
        throw error;
      }
    }
  }