import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, IconButton, InputAdornment, Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import createApis from '../../../api';

// Component for displaying and managing the user form
const UserForm = ({ open, handleClose, user, setUser, handleSave, editMode }) => {
    const { t } = useTranslation();

    const [roles, setRoles] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Using useState hook to manage form errors
    const [errors, setErrors] = useState({
        username: false,
        email: false,
        emailInvalid: false,
        password: false,
        passwordShort: false,
        role: false
    });

    // Fetch roles data on component mount
    useEffect(() => {
        // Use Api services for roles Api handlers
        const rolesApi = createApis('roles');

        async function fetchRoles() {
            try {
                const response = await rolesApi.fetchAll();
                setRoles(response);
            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        }

        fetchRoles();
    }, []); // Empty dependency array to run only once on mount

    // Function to validate form fields
    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const newErrors = {
            username: user.username.trim() === '',
            email: user.email.trim() === '',
            emailInvalid: user.email.trim() !== '' && !emailRegex.test(user.email.trim()),
            password: editMode ? false : user.password.trim() === '',
            passwordShort: editMode ? false : user.password.trim().length > 0 && user.password.trim().length < 8,
            role: !user.role_id
        };

        setErrors(newErrors);

        // Check if any field is invalid
        return Object.values(newErrors).every((error) => !error);
    };

    // Function to handle the change of form fields
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
            setUser({ ...user, [name]: type === 'checkbox' ? checked : value });
    };

    // Handler for save button click
    const handleSaveClick = () => {
        if (validateForm()) {
            handleSave();
            handleClose();
        }
    };

    // Handler for modal close
    const handleModalClose = () => {
        // Reset form errors when the modal closes
        setErrors({
            username: false,
            email: false,
            emailInvalid: false,
            password: false,
            passwordShort: false,
            role: false
        });

        handleClose();
    };

    // Inline style for modal content
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={open} onClose={handleModalClose}>
            <Box sx={{ ...style, width: 400 }}>
                <h2>{editMode ? t('editUser') : t('addUser')}</h2>
                <TextField
                    margin="normal"
                    name="username"
                    label={t('username')}
                    fullWidth
                    value={user.username}
                    error={errors.username}
                    helperText={errors.username && t('usernameRequired')}
                    onChange={handleChange}
                />
                <TextField
                    margin="normal"
                    name="email"
                    label={t('email')}
                    fullWidth
                    value={user.email}
                    error={errors.email || errors.emailInvalid}
                    helperText={errors.email ? t('emailRequired') : errors.emailInvalid ? t("emailInvalid") : ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="password"
                    label={t('password')}
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={user.password}
                    error={errors.password || errors.passwordShort}
                    helperText={errors.password ? t('passwordRequired') : errors.passwordShort ? t('passwordShort') : ''}
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControl fullWidth margin="normal" error={errors.role}>
                    <InputLabel id="roles-label">{t('userRole')}</InputLabel>
                    <Select
                        labelId="roles-label"
                        id="roles"
                        value={user.role_id}
                        name="role_id"
                        label={t('userRole')}
                        onChange={handleChange}
                    >
                        {roles.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.role_name}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* {errors.role && <FormHelperText>{t('userRoleRequired')}</FormHelperText>} */}
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={user.state}
                            onChange={handleChange}
                            name="state"
                            color="primary"
                        />
                    }
                    label={t('state')}
                />
                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                    >
                        {t('save')}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UserForm;
