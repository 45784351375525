export const createEntityApis = (apiService) => ({
  fetchAll: async () => {
    return apiService.apiRequest("/all");
  },

  fetchEntityById: async (entityId) => {
    return apiService.apiRequest(`/${entityId}`);
  },

  add: async (newEntity) => {
    return apiService.apiRequest("/create", "POST", newEntity);
  },

  edit: async (entityId, updatedEntity) => {
    return apiService.apiRequest(`/${entityId}`, "PUT", updatedEntity);
  },

  deleteEntity: async (entityId) => {
    return apiService.apiRequest(`/${entityId}`, "DELETE");
  },

  deleteSelectedEntities: async (selectedRows) => {
    return apiService.apiRequest("/batch", "DELETE", { ids: selectedRows });
  },

  deleteAllEntities: async () => {
    return apiService.apiRequest("/all", "DELETE");
  },

  getStatistics: async () => {
    return apiService.apiRequest("/statistics");
  },
});
