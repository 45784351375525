import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { decodeToken, isTokenExpired } from "../utils/tokenUtils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("authToken", null);
  const [user, setUser] = useLocalStorage("user", null);

  const navigate = useNavigate();


  const clearAuth = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("userPrivileges");
    localStorage.removeItem("authToken");
  };

  const processToken = (token) => {
    if (!token || isTokenExpired(token)) {
      return null;
    }

    const decoded = decodeToken(token);
    if (!decoded || !decoded.user_id) {
      return null;
    }

    return {
      user_id: decoded.user_id,
      username: decoded.username
    };
  };

  const updateAuthState = (newToken) => {
    const userData = processToken(newToken);
    
    if (!userData) {
      clearAuth();
      return false;
    }

    setToken(newToken);
    setUser(userData);
    return true;
  };

  // Initial auth state setup
  useEffect(() => {
    updateAuthState(token);
  }, []);


   // Token validation interval
   useEffect(() => {
    if (!token) return;

    const intervalId = setInterval(() => {
      if (!updateAuthState(token)) {
        navigate("/", { replace: true });
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [token, navigate]);


  const login = async (data) => {
    if (!data?.token) {
      throw new Error("Invalid login data");
    }

    if (updateAuthState(data.token)) {
      navigate("/dashboard");
    } else {
      throw new Error("Invalid token");
    }
  };

  const logout = () => {
    // Clear user authentication and other relevant state
    clearAuth();
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const useAuthForm = (initialState = {}) => {
  const getInitialFormData = () => ({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
    ...initialState,
  });

  const [formData, setFormData] = useState(getInitialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => setFormData(getInitialFormData);

  return {
    formData,
    handleChange,
    resetForm,
  };
};
