import React, { useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Admin Components
import AdminPanel from '../components/admin/AdminPanel';
import Users from '../components/admin/users/Users';
import UserRoles from '../components/admin/user_roles/UserRoles';

// Material UI Components
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Custom Components
import Sidebar from '../components/admin/Sidebar';

// Theme Hook for Responsiveness
import { useResponsive } from '../theme';

const AdminPanelLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Responsive hook
  const { isSmallScreen } = useResponsive();

  // State to track if the sidebar is collapsed
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(isSmallScreen);

  // Drawer width settings based on screen size and collapse state
  const drawerWidth = isSidebarCollapsed ? 60 : 240;

  const getCurrentPath = (pathname) => {
    switch (pathname) {
      case '/admin':
        return t('home');
      case '/admin/users':
        return t('users');
      case '/admin/user-roles':
        return t('userRoles');
      default:
        return t('adminPanelTitle');
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', overflowX: 'hidden' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          transition: 'all 0.3s ease',
          paddingLeft: isSidebarCollapsed ? '8px' : '24px',
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ width: '100%' }}>
            {getCurrentPath(location.pathname)}
          </Typography>
        </Toolbar>
      </AppBar>
      <Sidebar
        drawerWidth={drawerWidth}
        isCollapsed={isSidebarCollapsed}
        onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          maxWidth: '100%',
          bgcolor: 'background.default',
          p: isSmallScreen ? 2 : 3,
          pt: 12,
          overflowX: 'hidden',
        }}
      >
        <Routes>
          <Route path="/" element={<AdminPanel />} />
          <Route path="users" element={<Users />} />
          <Route path="user-roles" element={<UserRoles />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminPanelLayout;
