import React, { useState, useEffect } from "react";
import axios from "axios";
import formFields from "./formFields";
import "./CreateForm.css";
import { Navbar } from 'react-bootstrap';
import createApis from '../api';


const CreateForm = () => {
  const [formData, setFormData] = useState(formFields);
  const [hits, setHits] = useState(0);

  const technologiesApi = createApis('technologies');


  // Retrieve hits from localStorage on component mount
  useEffect(() => {
    const storedHits = localStorage.getItem('hits');
    if (storedHits !== null) {
      setHits(Number(storedHits));
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = { data: formData};
      const response = await technologiesApi.createNewRecord(body);
      
      console.log("............",response.result);
      alert(response.result);
    } catch (error) {
      console.error("Error updating data", error);
    }
  };

  const isSubmitDisabled = !formData["Name der Technologie"] || !formData["Technologiebeschreibung"];

  return (
    <>
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className="hit-center">
            <strong>Treffer: {hits === -1 ? 0 : hits}</strong>
      </div>
      <form onSubmit={handleSubmit}>
        {Object.keys(formData).map((key) => (
          <div key={key} className="form-group">
            <label>{key}:</label>
            <input
              type="text"
              name={key}
              value={formData[key]}
              onChange={handleChange}
              className="form-input"
            />
          </div>
        ))}
        <button type="submit" className="btn btn-primary" disabled={isSubmitDisabled}>
          Create
        </button>
      </form>
    </>
  );
};

export default CreateForm;
