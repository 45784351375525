import React, { useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, CardContent, Typography, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import createApis from "../../../api";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import { useStateContext } from "../../../context/stateContext";
import { useCrudHandlers, useDeleteHandlers } from "../../../handlers";

const UserLogs = () => {
  const { t } = useTranslation();

  // Destructuring handlers and state from custom hook
  const { entities, selectedRows, setSelectedRows } = useStateContext();

  // Use Api services for logs Api handlers
  const logsApi = createApis("logs");

  const { fetchAllEntities } = useCrudHandlers(logsApi);

  const {
    isBulkDelete,
    deleteDialogOpen,
    deleteEntityId,
    setDeleteDialogOpen,
    handleDeleteEntityConfirm,
    handleDeleteEntityInitiate,
    handleDeleteSelectedEntitiesConfirm,
  } = useDeleteHandlers(logsApi);

  useEffect(() => {
    fetchAllEntities();
    // eslint-disable-next-line
  }, []);

  // Define columns for DataGrid
  const columns = [
    { field: "username", headerName: t("username"), width: 150 },
    { field: "email", headerName: t("email"), width: 300 },
    { field: "action", headerName: t("action"), width: 300 },
    { field: "timestamp", headerName: t("timestamp"), width: 200 },
  ];

  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        borderBottom: "2px solid",
        borderBottomColor: "primary.main",
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Typography variant="h5" component="div">
            {t("userLogs")}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleDeleteEntityInitiate(null, true)}
              disabled={selectedRows.length === 0}
            >
              {t("deleteSelected")}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDeleteEntityInitiate("all", false)}
              disabled={entities.length === 0}
            >
              {t("deleteAll")}
            </Button>
          </Stack>
        </Stack>

        <DataGrid
          rows={entities}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={setSelectedRows}
          rowSelectionModel={selectedRows}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
        />

        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          message={
            deleteEntityId === "all"
              ? t("deleteAllLogsConfirmation")
              : isBulkDelete
              ? t("deleteSelectedLogsConfirmation", {
                  count: selectedRows.length,
                })
              : t("deleteLogConfirmation")
          }
          handleClose={() => setDeleteDialogOpen(false)}
          handleConfirm={
            deleteEntityId === "all"
              ? handleDeleteEntityConfirm
              : isBulkDelete
              ? handleDeleteSelectedEntitiesConfirm
              : handleDeleteEntityConfirm
          }
        />
      </CardContent>
    </Card>
  );
};

export default UserLogs;
