import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Menu Icons
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import SecurityIcon from "@mui/icons-material/Security";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Material UI Components
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";

// Hooks for auth
import { useAuth } from "../../hooks/useAuth";

const Sidebar = ({ drawerWidth, isCollapsed, onToggle }) => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const listItemButtonStyle = {
    "&:hover": {
      backgroundColor: "primary.main",
      color: "#ffffff",
    },
  };

  return (
    <Drawer
      sx={{
        width: isCollapsed ? 60 : drawerWidth, // Adjust width based on state
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? 60 : drawerWidth, // Adjust drawer paper width
          boxSizing: "border-box",
          backgroundColor: "#25293c",
          color: "#e1def5e6",
          overflowX: "hidden", // Ensure no horizontal scroll in the drawer
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton onClick={onToggle} sx={{ color: "#e1def5e6" }}>
            {isCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>
          {!isCollapsed && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ color: "#e1def5e6", marginTop: 1 }}
            >
              {t("adminPanelTitle")}
            </Typography>
          )}
        </Box>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/admin" sx={listItemButtonStyle}>
            <ListItemIcon>
              <HomeIcon sx={{ color: "text.light" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary={t("home")} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} to="users" sx={listItemButtonStyle}>
            <ListItemIcon>
              <PeopleIcon sx={{ color: "text.light" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary={t("users")} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="user-roles"
            sx={listItemButtonStyle}
          >
            <ListItemIcon>
              <SecurityIcon sx={{ color: "text.light" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary={t("userRoles")} />}
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/dashboard"
            sx={listItemButtonStyle}
          >
            <ListItemIcon>
              <GridViewRoundedIcon sx={{ color: "text.light" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary={t("dashboard")} />}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={logout} sx={listItemButtonStyle}>
            <ListItemIcon>
              <LogoutRoundedIcon sx={{ color: "text.light" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary={t("logout")} />}
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
