import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Css Files
import "./App.css";

// App Layouts
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Dashboard from "./components/Dashboard";
import AdminPanelLayout from "./layouts/AdminPanelLayout";

// Hooks
import { AuthProvider } from "./hooks/useAuth";

// Utils
import { ProtectedRoute, LoginRoute, AdminRoute } from "./utils/PrivateRoutes";

import { StateProvider } from "./context/stateContext";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import theme from "./theme";
import CreateForm from "./components/CreateForm";
// import UpdateForm from "./components/UpdateForm";
import FetchDataForm from "./components/FetchDataForm";
import DeleteDataForm from "./components/DeleteDataForm";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <AuthProvider>
      <StateProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="/"
              element={
                  <MainLayout>
                    <LoginRoute element={<AuthLayout />} />
                  </MainLayout>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/*"
              element={
                <AdminRoute>
                    <MainLayout>
                      <AdminPanelLayout />
                    </MainLayout>
                </AdminRoute>
              }
            />
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CreateForm />
                  </ThemeProvider>
                </ProtectedRoute>
              }
            />

            <Route
              path="/update"
              element={
                <ProtectedRoute>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <FetchDataForm />
                  </ThemeProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path="/delete"
              element={
                <ProtectedRoute>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <DeleteDataForm />
                  </ThemeProvider>
                </ProtectedRoute>
              }
            />
          </Routes>
        </QueryClientProvider>
        </StateProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

