export const createAuthApis = (apiService) => ({
    login: async (username, password) => {
      return apiService.apiRequest(
        "/login", 
        "POST", 
        { username, password }
      );
    },
  
    register: async (username, password, email) => {
      return apiService.apiRequest(
        "/register", 
        "POST", 
        { username, password, email }
      );
    }
  });
