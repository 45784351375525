import { useSnackbarHandlers } from './snackbarHandlers';
import { useAuth } from '../hooks/useAuth'; 
import createApis from "../api";

const apiService = createApis("auth");

export const useFormSubmitHandlers = ({ isRegister = false, formData, onSuccess }) => {
  const { handleSnackbar } = useSnackbarHandlers();
  const { login: authLogin } = useAuth();

  const { username, email, password, confirmPassword } = formData;

  // Extract auth functions from ApiService
  const { login, register } = apiService;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isRegister && password !== confirmPassword) {
      handleSnackbar("Passwords do not match", "error");
      return;
    }

    try {
      if (isRegister) {
        await register(username, password, email);
        handleSnackbar("Registration successful", "success");
        if (onSuccess) onSuccess();
      } else {
        const {token} = await login(username, password);
        
        await authLogin({ token });
        handleSnackbar("Login successful", "success");
      }
      
    } catch (error) {
      console.error(`Error ${isRegister ? "registering" : "logging in"}:`, error);
      handleSnackbar(error.message || `${isRegister ? "Registration" : "Login"} failed`, "error");
    }
  };

  return { 
    handleSubmit,
  };
};