import ApiService from './ApiService';
import { createAuthApis } from './authApis';
import { createEntityApis } from './entityApis';
import { createTechnologyApis } from './technologyApis';

export const createApis = (endpoint) => {
  const apiService = new ApiService(`${process.env.REACT_APP_PYTHON_URL}/${endpoint}`);
  return {
    ...createAuthApis(apiService),
    ...createEntityApis(apiService),
    ...createTechnologyApis(apiService)
  };
};

export default createApis;