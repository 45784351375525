import React, { useState } from 'react';
import UpdateForm from './UpdateForm';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Navbar } from 'react-bootstrap';

import createApis from '../api';

const FetchDataForm = () => {
  const [id, setId] = useState('');
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const technologiesApi = createApis('technologies');


  // Function to handle input change
  const handleInputChange = (event) => {
    setId(event.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    try {
      const jsonData = await technologiesApi.getById(id);
      
      setData(jsonData);
      setError(null);
    } catch (error) {
      setError(error.message);
      setData(null);
    }
  };

  return (
    <div className="container mt-5">
      <Navbar bg="light" expand="md" fixed="top" className="container-nav navbar-custom d-flex justify-content-center align-items-center">
        <Navbar.Brand href="/" className="color-maroon">
          KI-Kompass Inklusiv
        </Navbar.Brand>
      </Navbar>

      <br />
      <br/>
      <form onSubmit={handleSubmit} className="form-inline">
        <div className="form-group mb-2">
          <label htmlFor="id" className="sr-only">ID:</label>
          <input
            type="text"
            id="id"
            value={id}
            onChange={handleInputChange}
            className="form-control mr-2"
            placeholder="Please Enter the ID"
          />
        </div>
        <button type="submit" className="btn btn-primary mb-2">Submit</button>
      </form>

      {/* Display fetched data or error message */}
      {data && (
        <div className="mt-4">
          <h6 className="text-success">Success</h6>
          <UpdateForm fetchedDataById={data.result} id={id} />
        </div>
      )}
      {error && <p className="text-danger mt-2">{error}</p>}
    </div>
  );
};

export default FetchDataForm;
