import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import RoleForm from "./RoleForm";
import Actions from "../common/Actions";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import usePrivilegesMap from "./privilegesMap";
import createApis from "../../../api";
import { useStateContext } from "../../../context/stateContext";
import {
  useCrudHandlers,
  useFormHandlers,
  useDeleteHandlers,
} from "../../../handlers";

const UserRoles = () => {
  const { t } = useTranslation();

  const newRole = { role_name: "", description: "", privileges: ["view"] };

  // Destructuring handlers and state from custom hook
  const { entities, selectedRows, setSelectedRows } =
    useStateContext();

  // Use Api services for roles Api handlers
  const rolesApi = createApis('roles');

  const { handleSaveCell, fetchAllEntities, handleSaveEntity } =
    useCrudHandlers(rolesApi);

  const {
    isBulkDelete,
    deleteDialogOpen,
    setDeleteDialogOpen,
    handleDeleteEntityConfirm,
    handleDeleteEntityInitiate,
    handleDeleteSelectedEntitiesConfirm,
  } = useDeleteHandlers(rolesApi);

  const {
    newEntity,
    editMode,
    openEntityForm,
    setNewEntity,
    handleOpenEntityForm,
    handleCloseEntityForm,
  } = useFormHandlers();


  const privilegesMap = usePrivilegesMap();

  // Fetch roles data on component mount
  useEffect(() => {
    fetchAllEntities();
    // eslint-disable-next-line
  }, []);

  // Define columns for DataGrid
  const columns = [
    {
      field: "role_name",
      headerName: t("roleName"),
      width: 150,
      editable: false,
    },
    {
      field: "description",
      headerName: t("description"),
      width: 600,
      editable: false,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            overflowX: "auto",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "privileges",
      headerName: t("privileges"),
      width: 350,
      editable: false,
      renderCell: (params) =>
        params.value?.map((privilege) => (
          <Chip
            key={privilege}
            label={privilegesMap[privilege].label}
            size="small"
            color={privilegesMap[privilege].color}
            variant="outlined"
            style={{ marginRight: "5px", marginBottom: "5px" }}
          />
        )),
    },
    {
      field: "actions",
      headerName: t("actions"),
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Actions
          onEdit={() => handleOpenEntityForm(params.row)}
          onDelete={() => handleDeleteEntityInitiate(params.row.id)}
        />
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box mb={2} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenEntityForm(newRole)}
          style={{ marginRight: "10px" }}
        >
          {t("addRole")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDeleteEntityInitiate(null, true)}
          disabled={selectedRows.length === 0}
        >
          {t("deleteSelected")}
        </Button>
      </Box>
      <DataGrid
        rows={entities}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        onCellEditStop={handleSaveCell}
        selectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
      />
      <RoleForm
        open={openEntityForm}
        handleClose={handleCloseEntityForm}
        role={newEntity}
        setRole={setNewEntity}
        handleSave={
          editMode
            ? () => handleSaveEntity("PUT", newEntity)
            : () => handleSaveEntity("POST", newEntity)
        }
        editMode={editMode}
        privilegesOptions={privilegesMap}
      />
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        message={
          isBulkDelete
            ? t("deleteRolesConfirmation")
            : t("deleteRoleConfirmation")
        }
        handleClose={() => setDeleteDialogOpen(false)}
        handleConfirm={
          isBulkDelete
            ? handleDeleteSelectedEntitiesConfirm
            : handleDeleteEntityConfirm
        }
      />
    </div>
  );
};

export default UserRoles;
